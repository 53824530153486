import { useCallback, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { SelectOption } from "../store/models/DashboardModel";
import { IDataATDP } from "../store/models/CreateAtdp";
import useClientWebSocket from "./useClientWebSocket";
import {
  GetDocumentTypeAC,
  GetFingerDeviceAC,
} from "../store/action-creators/FingerprintActionCreators";
import { getTypeProcessViewCapturadorAC } from "../store/action-creators/TypeProcessActionCreators";
import { IDataFinger } from "../store/models/FingerPrintModel";
import {
  FingerPrintSelector,
  setLoadingFingerPrint,
  setSelectProcess,
} from "../store/slices/FingerPrintSlice";
import { TypeProcessSelector } from "../store/slices/TypeProcessSlice";
import { MESSAGES } from "../utils/types/const";

interface IUseFingerPrintCapture {
  enqueueSnackbar: any;
  roleLogged: "FNA" | "INT" | "NRM";
}

type stateFormParticipants = {
  idParticipant: number;
  stateForm: boolean;
};

const FingerPrintSchema = yup.object().shape({
  procedure: yup
    .string()
    .max(100, `${MESSAGES.max} 100`)
    .required(MESSAGES.required),
});

const useFingerPrintCaptureForm = ({
  enqueueSnackbar,
  roleLogged,
}: IUseFingerPrintCapture) => {
  type FingerPrintFormData = yup.InferType<typeof FingerPrintSchema>;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FingerPrintFormData>({
    mode: "onSubmit",
    resolver: yupResolver(FingerPrintSchema),
    shouldUnregister: false,
  });

  const dispatch = useDispatch();

  //#region  global states
  const { fingerDevice, loadingFingerPrint } = useSelector(FingerPrintSelector);
  const { typeProcesses, sizeTypeProcess } = useSelector(TypeProcessSelector);

  //#region  local states
  const [template, setTemplate] = useState(false);
  const [arrayFormsToValidate, setArrayFormsToValidate] = useState<
    Array<stateFormParticipants>
  >([
    {
      idParticipant: 1,
      stateForm: false,
    },
  ]);
  const [numberParticipantsLocal, setNumberParticipantsLocal] =
    useState<number>(1);
  const [submitState, setSubmitState] = useState<boolean>(false);
  const [processTypeState, setProcessTypeState] = useState<Array<SelectOption>>(
    []
  );
  const [primaryParticipant, setPrimaryParticipant] = useState<IDataATDP>({
    noCedula: "",
    name: "",
    surname: "",
    place: "",
  });

  // React hooks
  const {
    DisconnectClientInfo,
    showModalAlertDevice,
    setShowModalAlertDevice,
    responseSocketTemp,
    ConnectClientInfo,
  } = useClientWebSocket({ enqueueSnackbar });

  // custom functions

  function handleStatesToFalse() {
    setTemplate(false);
    DisconnectClientInfo();
    dispatch(setLoadingFingerPrint(false));
  }
  // handle number participants
  const handleNumberParticipants = (numberParticipantsParam: number) => {
    if (numberParticipantsParam < numberParticipantsLocal) {
      const arrayChanged = arrayFormsToValidate;

      arrayChanged.pop();

      setArrayFormsToValidate(arrayChanged);
    }
    if (numberParticipantsParam >= numberParticipantsLocal) {
      const arr = arrayFormsToValidate;

      arr.push({ idParticipant: numberParticipantsParam, stateForm: false });

      setArrayFormsToValidate(arr);
    }

    let participants = Object.keys(sessionStorage)
      .filter((participant) => !isNaN(Number(participant[0])))
      .filter((participant) => participant > String(numberParticipantsLocal));

    if (participants.length > 0) {
      participants.map((participant) => {
        return sessionStorage.removeItem(participant);
      });
    }

    setNumberParticipantsLocal(numberParticipantsParam);
  };

  // process type selectors
  const returnProcessType = () => {
    typeProcesses?.map((itemProcess: { id: number; name: string }) => {
      const newProcessType: SelectOption = {
        key: itemProcess.id ? itemProcess.id : 1,
        value: itemProcess.name ? itemProcess.name : "",
        label: itemProcess.name ? itemProcess.name : "",
      };

      if (!processTypeState.some((type) => type.key === newProcessType.key)) {
        return setProcessTypeState((prev) => [...prev, newProcessType]);
      }

      return setProcessTypeState((prev) => prev);
    });
  };

  const handleDataSaveForm = (
    data: IDataFinger,
    validate: Function,
    index: number
  ) => {
    if (data.id !== 0) {
      sessionStorage.setItem(data.id.toString(), JSON.stringify(data));
      if (data.id === 1) {
        setPrimaryParticipant({
          name: data.nameuser,
          noCedula: data.documentNumber,
          surname: data.surname,
          place: "",
        });
      }
    }
    validate(index);
  };

  // validate fields fom form
  const handleDisabledSubmit = () => {
    const response = arrayFormsToValidate.every((item) => !!item.stateForm);
    setSubmitState(response);
  };

  const handleValidate = (objectState: stateFormParticipants) => {
    const arr = [...arrayFormsToValidate];
    arr?.map((item) => {
      if (item.idParticipant == objectState.idParticipant) {
        return (item.stateForm = objectState.stateForm);
      }
    });

    setArrayFormsToValidate(arr);
    handleDisabledSubmit();
  };

  const handleProcessType = (valueString: any) => {
    let index = typeProcesses?.findIndex(
      (item: { name: any }) => item.name === valueString
    );

    if ("description" in typeProcesses) {
      dispatch(
        setSelectProcess({
          idProcessType: typeProcesses[index].id ? typeProcesses[index].id : 0,
          descriptionProcess: typeProcesses[index].description,
        })
      );
    } else {
      dispatch(
        setSelectProcess({
          idProcessType: typeProcesses[index].id,
          descriptionProcess: "Sin descripción",
        })
      );
    }
  };

  // Wrap custom action creator for GetFingerprintDevice service and action creator
  const dispatchActionCreatorDevice = (_responseSocketTemp?: any) => {
    dispatch(
      GetFingerDeviceAC(
        {
          MAC: _responseSocketTemp
            ? _responseSocketTemp.mac
            : responseSocketTemp.mac,
          serial: _responseSocketTemp
            ? _responseSocketTemp.deviceSerial
            : responseSocketTemp.deviceSerial,
        },
        enqueueSnackbar,
        DisconnectClientInfo
      )
    );
  };

  // handle submit
  // dispatch on store response from socket
  const submitForm = () => {
    dispatch(setLoadingFingerPrint(true));
    if (!responseSocketTemp.responseCode) {
      dispatchActionCreatorDevice();
    } else {
      ConnectClientInfo(dispatchActionCreatorDevice);
    }
  };
  //   React hook state
  useEffect(() => {
    dispatch(GetDocumentTypeAC());
    dispatch(getTypeProcessViewCapturadorAC());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeTypeProcess]);

  useEffect(() => {
    returnProcessType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeProcesses, sizeTypeProcess]);

  useEffect(() => {
    handleDisabledSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayFormsToValidate.length]);

  useEffect(() => {
    if (fingerDevice?.statusCode === 200) {
      setTemplate(true);
    } else {
      setTemplate(false);
    }
  }, [fingerDevice]);

  useEffect(() => {
    if (!!responseSocketTemp.httpStatus) {
      if (!responseSocketTemp.responseCode) {
        enqueueSnackbar(`Conexión con el huellero establecida`, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(`${responseSocketTemp.message}`, {
          variant: "error",
        });
      }
      dispatch(setLoadingFingerPrint(false));
    }
  }, [responseSocketTemp]);

  return {
    handleProcessType,
    handleValidate,
    submitForm,
    handleDataSaveForm,
    handleStatesToFalse,
    handleSubmit,
    handleNumberParticipants,
    setTemplate,
    setDeviceAlert: setShowModalAlertDevice,
    control,
    deviceAlert: showModalAlertDevice,
    errors,
    loadingFingerPrint,
    numberParticipantsLocal,
    primaryParticipant,
    processTypeState,
    submitState,
    template,
  };
};

export default useFingerPrintCaptureForm;
