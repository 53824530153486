import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import { ControlledSwitch } from "../../components/Inputs/ControlledSwitch";
import { InputRounded } from "../../components/Inputs/InputRounded";
import { SelectInputRounded } from "../../components/Inputs/SelectInputRounded";
import { bussinesUnitSchema } from "../../utils/schemas/schemas";

import { CustomTypography } from "../../components/typography/CustomTypography";
import { BusinessUnitSelector } from "../../store/slices/BusinessUnitSlice";
import { IBusinessUnit } from "../../store/models/BusinessUnit";
import { yupResolver } from "@hookform/resolvers/yup";

import { AlertIconModal } from "../../components/modal/AlertIconModal";
import { CompanyIcon } from "../../components/Icons/CompanyIcon";
import useBusinessUnitBasicData from "../../hooks/useBusinessUnitBasicData";

const BusinessUnitBasicData: React.FC = () =>  {
  const {
    currentBusiness,
    isEdit,
  } = useSelector(BusinessUnitSelector);
  const [listCities, setListCities] = useState<any>([]);
  // formulario
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid },
    setValue,
  } = useForm<IBusinessUnit>({
    shouldUnregister: false,
    mode: "onChange",
    resolver: yupResolver(bussinesUnitSchema),
    defaultValues: !currentBusiness
      ? {
          isDependent: false,
          businessUnit: 0,
        }
      : {
          ...currentBusiness,
          isDependent:
            currentBusiness.businessUnit === currentBusiness.id ||
            currentBusiness.businessUnit === null
              ? false
              : true,
          businessUnit:
            currentBusiness.businessUnit !== currentBusiness.id
              ? currentBusiness.businessUnit
              : 0,
        },
  });

  const {
    handleForm,
    submitForm,
    handleCloseModal,
    businessUnitsListCommon,
    statesAndCities,
    isSuccess,
    showModal,
  } = useBusinessUnitBasicData({ reset, setValue });

  useEffect(() => {
    const department = watch("department");
    if (department) {
      const departmentSel = statesAndCities.find((item) => {
        return item.id === department;
      });
      setListCities(departmentSel?.city);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("department")]);

  return (
    <form onChange={handleForm} onSubmit={handleSubmit(submitForm)}>
      <Grid container>
        <Grid container item lg={8} md={10} sm={11} xs={11} spacing={2}>
          <Grid item lg={10} md={12} sm={12} xs={12}>
            <InputRounded
              name="code"
              control={control}
              label="Código"
              props={{
                name: "code",
                helperText: errors.code?.message,
                error: errors.code ? true : false,
                disabled: !isEdit,
              }}
            />
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={2} md={3} sm={6} xs={6}>
              <CustomTypography type="definition">Dependiente</CustomTypography>
            </Grid>
            <Grid item lg={4} md={6} sm={2} xs={2}>
              <ControlledSwitch
                name="isDependent"
                control={control}
                isDisabled={!isEdit}
                error={errors.isDependent ? true : false}
                helperText={errors.isDependent?.message}
              />
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {" "}
            <SelectInputRounded
              control={control}
              label={"Sedes"}
              name={"businessUnit"}
              props={{
                helperText: errors.businessUnit?.message,
                error: errors.businessUnit ? true : false,
                disabled: !isEdit || !watch("isDependent"),
              }}
              options={businessUnitsListCommon?.filter((function(obj) {
                if (currentBusiness != undefined) {
                  return obj.id != currentBusiness?.id ? obj : null;
                } else {
                  return obj;
                }
              })).map(function(item: { id: any; name: any; }) {
                return {
                key: item.id,
                label: item.name,
                value: item.id,
              }})
            }
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <InputRounded
              name="name"
              control={control}
              label="Nombre*"
              props={{
                name: "name",
                helperText: errors.name?.message,
                error: errors.name ? true : false,
                disabled: currentBusiness?.haveProcess ? true : !isEdit ,
              }}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <InputRounded
              name="phone"
              control={control}
              label="Teléfono*"
              props={{
                name: "phone",
                helperText: errors.phone?.message,
                error: errors.phone ? true : false,
                disabled: !isEdit,
              }}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <InputRounded
              name="email"
              control={control}
              label="Email*"
              props={{
                name: "email",
                helperText: errors.email?.message,
                error: errors.email ? true : false,
                disabled: !isEdit,
              }}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <InputRounded
              name="address"
              control={control}
              label="Dirección*"
              props={{
                name: "address",
                helperText: errors.address?.message,
                error: errors.address ? true : false,
                disabled: !isEdit,
              }}
            />
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SelectInputRounded
                control={control}
                label={"Departamento"}
                name={"department"}
                props={{
                  helperText: errors.department?.message,
                  error: errors.department ? true : false,
                  disabled: !isEdit,
                }}
                options={statesAndCities?.map((item) => ({
                  key: item.id,
                  label: item.stateName,
                  value: item.id,
                }))}
                onChange={(e: number | undefined) => {
                  if (e !== currentBusiness?.city) {
                    setValue("city", 0);
                  }
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SelectInputRounded
                control={control}
                label={"Ciudad"}
                name={"city"}
                props={{
                  helperText: errors.city?.message,
                  error: errors.city ? true : false,
                  disabled: !isEdit,
                }}
                options={
                  listCities
                    ? listCities.map((item: any) => ({
                        key: item.id,
                        label: item.cityName,
                        value: item.id,
                      }))
                    : []
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            justifyContent="flex-end"
          >
            <Grid item lg={3} md={4} sm={4} xs={12}>
              <PrimaryButton
                title={currentBusiness ? "Actualizar" : "Guardar"}
                props={{ type: "submit", disabled: !isValid || !isEdit }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item lg={6} md={4} sm={1} xs={1}></Grid>
      </Grid>
      <AlertIconModal
        open={showModal}
        handleClose={() => {
          handleCloseModal();
        }}
        icon={<CompanyIcon width={"92px"} height={"80px"} />}
        message={isSuccess}
      />
    </form>
  );
};

export default BusinessUnitBasicData;
