import { useEffect, useRef, useState } from "react";
import "./App.css";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Routes from "./routes/Routes";
import { FullLoader } from "@dg-bucaramanga/react-components-dg-qa";
import { decodeToken, decodeTokenData } from "./utils/general/utils";
import { SnackbarProvider } from "notistack";
import { authSelector, setAuth, setTokens } from "./store/slices/AuthSlice";
import { setRol, CommonSelector } from "./store/slices/CommonSlice";
import { getPersonalizationAC } from "./store/action-creators/CompanyActionCreators";
import { setPersonalization } from "./utils/general/personalization";
import { Roles } from "./utils/types/const";
import { ValidateVersionAC } from "./store/action-creators/AuthActionCreators";
import { NotifyActualizationSocket } from "./services/AuthServices";

function App() {
  // state REDUX
  const { isAuth, isLoading, tokens, updateDesktop } =
    useSelector(authSelector);
  const { rol } = useSelector(CommonSelector);
  const [theme, setTheme] = useState(createTheme());
  const dispatch = useDispatch();
  const scriptRef = useRef<any>(null);
  //auth
  // token recuperado de query string
  const encryptedToken = window.location.pathname.substring(1);

  if (encryptedToken.length > 50) {
    sessionStorage.removeItem("jwtToken");
    const token: any = decodeTokenData(encryptedToken);
    const tokenDecode = decodeToken(token.token);
    sessionStorage.setItem("jwtToken", token.token);
    sessionStorage.setItem("refreshToken", token.refreshToken);
    dispatch(
      setTokens({
        result: { token: token.token, refreshToken: token.refreshToken },
        error: {},
      })
    );
    dispatch(setAuth(true));
    const userRole = tokenDecode?.BIOMETRIA_ROLE;
    const userRoleToURL: Record<string, string> = {
      "BIOMETRIA RNEC": "/companylist",
      "BIOMETRIA CAPTURADOR MOVIL": "/fingerprint",
      "BIOMETRIA INTEGRACION": "/fingerprint",
      "BIOMETRIA CAPTURA MOVIL": "/fingerprint",
    };
    window.location.href = userRoleToURL[userRole ?? ""] || "/dashboard";
  } else if (!isAuth) {
    const token = sessionStorage.getItem("jwtToken");
    const refreshToken = sessionStorage.getItem("refreshToken");
    if (token && refreshToken) {
      const tokenDecode = decodeToken(token);
      dispatch(
        setTokens({
          error: "",
          result: { token: token, refreshToken: refreshToken },
        })
      );
      dispatch(setAuth(true));
      dispatch(
        setRol(tokenDecode === undefined ? "" : tokenDecode?.BIOMETRIA_ROLE)
      );
    }
  }

  // React hooks
  useEffect(() => {
    if (!!tokens && !!tokens.result?.token) {
      setPersonalization(null, setTheme);
      dispatch(getPersonalizationAC(tokens.result.token, setTheme));
    }
  }, [tokens, dispatch]);

  useEffect(() => {
    if (!rol.length) {
      dispatch(setRol(""));
    }
  }, [rol, dispatch]);

  //crear etiqueta script e insertar un texto que lance el popstate para bloquear ir hacia atras con el navegador solo cuando el rol sea RNEC
  useEffect(() => {
    const script = document.createElement("script");
    if (rol === Roles.BIOMETRIA_RNEC) {
      script.innerHTML = `
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function () {
      window.history.go(1);
      };`;
    }

    //verificar rol capturador y enviar el servicio de consulta de version de software Biometrics Desktop
    if (
      rol === Roles.BIOMETRIA_CAPTURADOR ||
      rol === Roles.BIOMETRIA_CAPTURADOR_MOVIL ||
      rol === Roles.BIOMETRIA_CAPTURA_MOVIL
    ) {
      dispatch(ValidateVersionAC());
    }

    script.type = "text/javascript";
    scriptRef.current.appendChild(script);
    return () => {
      scriptRef.current.removeChild(script);
    };
  }, [rol]);

  /* effect que espera la informacion que se almacena despues de consultar las actualizaciones del software de
  Biometrics Desktop y si tiene version pendiente conectar al socket de actualizacion de software */
  useEffect(() => {
    async function fetchData() {
      if (updateDesktop?.Update) {
        await NotifyActualizationSocket(updateDesktop);
      }
    }
    fetchData();
  }, [updateDesktop]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {isLoading ? (
          <FullLoader open={isLoading} viewLoader={true} />
        ) : (
          <Routes />
        )}
      </SnackbarProvider>
      <div ref={scriptRef}></div>
    </ThemeProvider>
  );
}

export default App;
