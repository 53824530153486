import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Grid, makeStyles, Theme } from "@material-ui/core";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { PrimaryButton } from "../../../components/button/PrimaryButton";
import { IDataATDPFNAINT } from "../../../store/models/CreateAtdp";
import {
  FingerPrintSelector,
  setLoadingFingerPrint,
  setSelectProcess,
} from "../../../store/slices/FingerPrintSlice";
import { getDataParticipantINT } from "../../../services/BioIntegratorServices";
import { getDataParticipantFNA } from "../../../services/FnaService";

import { InputRounded } from "../../../components/Inputs/InputRounded";
import FingerATDPFNA from "../../../components/Atdp/FingerATDPFNA";
import { StandardResponse } from "../../../type";
import FingerFormData_FNA_INT from "../../../components/Fingerprint/FingerFormData_FNA_INT";

const useStyles = makeStyles((_theme: Theme) => ({
  header: {
    padding: "0 !important",
    margin: "20px 0",
    display: "flex",
    flexDirection: "column",
    [_theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },

  containerText: {
    alignItems: "center",
    justifyContent: "flex-start",
    [_theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
  },

  increaseContainer: {
    display: "flex",
    justifyContent: "right",
    height: "30px",
  },

  formParticipants: {
    boxShadow: "0px 3px 6px #00000033",
    border: "1px solid #009BDD",
    padding: "20px",
    borderRadius: "10px",
    margin: 0,
    marginBottom: "20px",
  },

  buttonNextContainer: {
    display: "flex",

    justifyContent: "right",
    [_theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [_theme.breakpoints.up("sm")]: {
      maxWidth: "137px",
    },
  },
  titleParticipant: {
    font: "normal normal bold 18px/23px Muli",
    color: "#009BDD",
    marginBottom: 20,
  },
  inputCustom: {
    "& input": {
      background: "white",
      boxShadow: "0px 3px 3px #0000001A",
      border: "1px solid #D5D5D5",
      borderRadius: "10px",
      underline: "none",
    },

    "& div": {
      background: "none !important",
      textDecoration: "none",
    },

    "& label": {
      fontSize: "15px",
      font: "normal normal normal 16px/17px Muli",
    },

    "& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after": {
      borderBottom: "none",
    },
  },

  textFieldDisabled: {
    opacity: "1 !important",
  },
}));

interface IFingerprintFNAFormProps {
  formSubmit: Function;
  handleValidate: Function;
  handleChangeTab: Function;
  handleStatesToFalse: Function;
  haveGeolocatization: Boolean;
  roleLogged: string;
  setTemplate: React.Dispatch<React.SetStateAction<boolean>>;
  template: boolean;
}

interface IFingerPrintFormOne {
  procedure: string;
}

const FingerPrintSchemaFNA = yup.object().shape({
  procedure: yup.string().trim(),
});

const FingerprintForm_FNA_INT = ({
  formSubmit,
  handleValidate,
  haveGeolocatization,
  handleChangeTab,
  handleStatesToFalse,
  roleLogged,
  setTemplate,
  template,
}: IFingerprintFNAFormProps) => {
  // library hooks
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFingerPrintFormOne>({
    mode: "onSubmit",
    resolver: yupResolver(FingerPrintSchemaFNA),
    shouldUnregister: false,
  });
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  //#region  global states
  const { fingerDevice } = useSelector(FingerPrintSelector);

  //#region  local states
  const [procedure, setProcedure] = React.useState<string>("");
  const [submitState, setSubmitState] = React.useState<boolean>(false);
  const [primaryParticipant, setPrimaryParticipant] =
    React.useState<IDataATDPFNAINT>({
      typeProcess: "",
      documentNumber: "",
      documentType: "",
      idDocumentType: 0,
      idTypeProcess: 0,
      idDemand: 0,
    });
  const addCandidate = () => {
    dispatch(setLoadingFingerPrint(true));

    if (roleLogged === "FNA") {
      getDataParticipantFNA(enqueueSnackbar, disableLoagindFingerPrint).then(
        (data: StandardResponse<IDataATDPFNAINT>) => {
          setDataForm(data);
        }
      );
    } else {
      getDataParticipantINT(enqueueSnackbar, disableLoagindFingerPrint).then(
        (data: StandardResponse<IDataATDPFNAINT>) => {
          setDataForm(data);
        }
      );
    }
    setSubmitState(true);
  };

  const setDataForm = (data: StandardResponse<IDataATDPFNAINT>) => {
    setPrimaryParticipant({
      typeProcess: data.result.typeProcess,
      documentNumber: data.result.documentNumber,
      documentType: data.result.documentType,
      idDocumentType: data.result.idDocumentType,
      idTypeProcess: data.result.idTypeProcess,
      idDemand: data.result.idDemand,
    });
    dispatch(
      setSelectProcess({
        idProcessType: data.result.idTypeProcess,
        descriptionProcess: data.result.typeProcess,
      })
    );
    setProcedure(data.result.typeProcess);
  };

  const disableLoagindFingerPrint = () => {
    dispatch(setLoadingFingerPrint(false));
  };

  useEffect(() => {
    if (fingerDevice?.statusCode === 200) {
      setTemplate(true);
    } else {
      setTemplate(false);
    }
  }, [fingerDevice]);

  useEffect(() => {
    sessionStorage.setItem(
      "1",
      JSON.stringify({ ...primaryParticipant, id: 1 })
    );
  }, [primaryParticipant]);

  return (
    <>
      <form
        onSubmit={handleSubmit((data: any) => {
          formSubmit(data);
        })}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction={"column"}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <Container className={classes.header}>
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              spacing={3}
              justifyContent="space-between"
            >
              <Grid item lg={8} md={8} sm={7} xs={12}>
                <InputRounded
                  name="procedure"
                  label="Trámite"
                  props={{
                    name: "procedure",
                    helperText: errors.procedure?.message,
                    error: errors.procedure ? true : false,
                    disabled: true,
                    autoComplete: "off",
                    value: procedure,
                  }}
                  control={control}
                />
              </Grid>
              <Grid
                container
                item
                lg={3}
                md={3}
                sm={3}
                xs={8}
                className={classes.containerText}
              >
                <PrimaryButton
                  title={"Agregar Candidato"}
                  props={{
                    style: { display: "flex", fontSize: "0.8em" },
                    type: "button",
                    disabled: haveGeolocatization ? false : true,
                    onClick: addCandidate,
                  }}
                />
              </Grid>
            </Grid>
          </Container>

          <Container style={{ padding: 0 }}>
            <FingerFormData_FNA_INT
              handleValidateProp={handleValidate}
              isOpen={true}
              dataParticipant={primaryParticipant}
            />
          </Container>
          <Container
            style={{
              display: "flex",
              justifyContent: "end",
              margin: 0,
              padding: 0,
            }}
          >
            <div className={classes.buttonNextContainer}>
              <PrimaryButton
                title={"Siguiente"}
                props={{
                  style: { display: "flex" },
                  endIcon: <ArrowForwardIos style={{ fontSize: "25px" }} />,
                  type: "submit",
                  disabled: !!submitState ? false : !submitState,
                }}
              />
            </div>
          </Container>
        </Grid>
      </form>
      <FingerATDPFNA
        data={primaryParticipant}
        onClick={() => {
          setTemplate(false);
          handleChangeTab(1);
        }}
        isOpen={template}
        isClose={handleStatesToFalse}
      />
    </>
  );
};
export { FingerprintForm_FNA_INT };
