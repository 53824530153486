import SockJS from "sockjs-client";
import Stomp from "stompjs";
import { URL_WEBSOCKET, DEV_MODE } from "../config/const";
import { IGeneralWebsocketResponse } from "../store/models/FingerPrintModel";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";
import { useDispatch } from "react-redux";
import { setLoadingFingerPrint } from "../store/slices/FingerPrintSlice";
import { getUrlWebSocketByRole } from "../utils/general/utils";

var stompClient: any = null;
var urlSocket = getUrlWebSocketByRole();
let responseConnect: string = "";
let responseSocketTemp: IGeneralWebsocketResponse = {
  dateTime: "",
  httpStatus: 0,
  message: "",
  responsePrintFinger: {
    deviceSerial: "",
    imageBase64: "",
    ip: "",
    mac: "",
    prinfingerTemplate: "",
  },
  responseCode: "",
};

interface IUseFingerWebSocket {
  fingerDevice? : any
}

const useFingerWebSocket = (fingerDevice? : any) => {
  
  var urlSocket = getUrlWebSocketByRole();
  const dispatch = useDispatch();

  const setResponseConnect = (re: any) => {
    if (re) {
      responseConnect = re;
    }
  };

  const returnResponseConnect = () => {
    return responseConnect;
  };

  const returnResponseSend = () => {
    return responseSocketTemp;
  };
  function isEmptyObject(obj: any){
    try {
      const objParsed = JSON.parse(obj) 
      return objParsed
    } catch (error) {
    }
  }

  /* Connect to topic using web sockets */
  const connectToFingerWebSocket = (
    onResponse: any,
    onTest: any,
    enqueueSnackbar: (
      message: SnackbarMessage,
      options?: OptionsObject
    ) => SnackbarKey
  ) => {
    if (urlSocket?.role === "movil") {
      let socket = new WebSocket(`${urlSocket.url}printfinger`);

      socket.onopen = (data) => {
        const deviceinfo = fingerDevice.result
        socket.send(
          JSON.stringify({
          device: `${deviceinfo.device}`,
          encrypt: `${deviceinfo.encrypt}`,
          image: false,
          companyId: `${deviceinfo.companyId}`,
          deviceId: `${deviceinfo.deviceId}`,
          deviceSerial: `${deviceinfo.serial}`,
          dev: DEV_MODE,
        }));
      }

      socket.onmessage = (_response) => {
        if(isEmptyObject(_response.data)){
          setResponseConnect("CONNECTED");
          onResponse(true);
          suscribe(onTest, _response.data);
          socket.close()
        }
      }

    } else {
      if(!!urlSocket){

        var socket = new SockJS(urlSocket.url + "printfinger", null, {});
        stompClient = Stomp.over(socket);
        stompClient.debug = null;
        stompClient.connect(
          {},
          (frame: any) => {
            setResponseConnect(frame.command);
            suscribe(onTest);
            onResponse(true);
          },
          (error: any) => {
            setResponseConnect(error);
            onResponse(false);
            enqueueSnackbar("Error de comunicación con biometria desktop.", {
              variant: "error",
            });
            dispatch(setLoadingFingerPrint(false));
          }
        );

        }
      }
   
  };

  /* Disconnect */
  function disconnect() {
    if (!!stompClient) {
      stompClient.disconnect();
    }else{

    }
  }

  const suscribe = (onTest: any, data?: any) => {
    if(!!stompClient){
      stompClient.subscribe("/topic/printfingerData", (message: any) => {
        callbackSuscribe(message, onTest);
      });
    }else{
      callbackSuscribe({body : data}, onTest);
    }
  };

  const callbackSuscribe = (message: any, onTest: any) => {
    if (message) {
      let r = JSON.parse(message.body);
      responseSocketTemp = {
        dateTime: r.dateTime,
        httpStatus: r.httpStatus,
        message: r.message,
        responsePrintFinger: r.responsePrintFinger,
        responseCode: r.responseCode,
      };
      if (onTest) onTest(responseSocketTemp);
    }
  };

  /* Send place */
  const sendSocket = (deviceInfo: any) => {
    if(!!stompClient){
      stompClient.send(
        "/app/printfinger",
        {},
        JSON.stringify({
          device: `${deviceInfo.device}`,
          encrypt: `${deviceInfo.encrypt}`,
          image: false,
          companyId: `${deviceInfo.companyId}`,
          deviceId: `${deviceInfo.deviceId}`,
          deviceSerial: `${deviceInfo.serial}`,
          dev: DEV_MODE,
        })
      );
    }else{

    }
  };

  function sendRequestSocket(deviceInfo: any) {
    sendSocket(deviceInfo);
  }

  return {
    sendRequestSocket,
    disconnect,
    connectToFingerWebSocket,
    returnResponseConnect,
    returnResponseSend,
  };
};

export default useFingerWebSocket;
