import SockJS from "sockjs-client";
import Stomp from "stompjs";
import { DEV_MODE } from "../config/const";
import { IClientInfo } from "../store/models/FingerPrintModel";
import { getUrlWebSocketByRole } from "../utils/general/utils";
import { useEffect, useState } from "react";

interface IUseClientWebSocket {
  enqueueSnackbar: any;
}

const useClientWebSocket = ({ enqueueSnackbar }: IUseClientWebSocket) => {
  // local states
  const [showModalAlertDevice, setShowModalAlertDevice] =
    useState<boolean>(false);
  const [responseSocketTemp, setResponseSocketTemp] = useState<IClientInfo>({
    dateTime: "",
    httpStatus: 0,
    ip: "",
    mac: "",
    deviceSerial: "",
    message: "",
    responseCode: null,
  });

  var stompClient: any = null;
  var socketWS: any = null;
  var urlSocket = getUrlWebSocketByRole();

  function parseObjectData(obj: any) {
    try {
      const objParsed = JSON.parse(obj);
      return objParsed;
    } catch (error) {}
  }

  const ConnectClientInfo = (_customFunction?: any) => {
    if (urlSocket?.role === "movil") {
      socketWS = new WebSocket(`${urlSocket.url}clientInfo`);

      socketWS.onopen = () => {
        socketWS.send("");
      };

      socketWS.onerror = () => {
        setShowModalAlertDevice(true);
      };

      socketWS.onmessage = (_response: { data: any }) => {
        const parsedObj = parseObjectData(_response.data);

        callbackSuscribe(parsedObj, _customFunction);
      };
    } else {
      if (!!urlSocket) {
        let socket = new SockJS(`${urlSocket.url}clientInfo`);
        stompClient = Stomp.over(socket);

        stompClient.connect(
          {},
          (frame: any) => {
            sendRequestClientInfo();

            stompClient.subscribe("/topic/clientInfo", (message: any) => {
              const parsedData = parseObjectData(message.body);
              callbackSuscribe(parsedData, _customFunction);
            });
          },
          (error: any) => {
            setShowModalAlertDevice(true);
          }
        );
      }
    }
  };

  function DisconnectClientInfo() {
    if (stompClient != null) {
      stompClient.disconnect();
    } else if (socketWS !== null) {
      socketWS.close();
    }
  }

  const callbackSuscribe = (_data: any, _customFunction?: any) => {
    if (_data) {
      setResponseSocketTemp({
        dateTime: _data.dateTime,
        httpStatus: _data.httpStatus,
        ip: _data.ip,
        mac: _data.mac,
        deviceSerial: _data.deviceSerial,
        message: _data.message,
        responseCode: _data.responseCode,
      });
      DisconnectClientInfo();

      if (!_data.responseCode && _customFunction) {
        _customFunction(_data);
      }
    }
  };

  const sendRequestClientInfo = () => {
    if (!!stompClient) {
      stompClient.send(
        "/app/clientInfo",
        {},
        JSON.stringify({
          dev: DEV_MODE,
        })
      );
    }
  };

  useEffect(() => {
    ConnectClientInfo();
  }, []);

  return {
    showModalAlertDevice,
    setShowModalAlertDevice,
    responseSocketTemp,
    ConnectClientInfo,
    DisconnectClientInfo,
  };
};

export default useClientWebSocket;
