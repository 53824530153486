import React, { useEffect } from "react";
import { makeStyles, Checkbox, Link } from "@material-ui/core";
import {
  Theme,
  Typography,
  Dialog,
  DialogContent,
  DialogProps,
} from "@material-ui/core";
import { PrimaryButton } from "../button/PrimaryButton";
import { SecondaryButton } from "../button/SecondaryButton";
import { useSelector } from "react-redux";
import { AtdpSelector } from "../../store/slices/AtdpSlice";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { IDataATDPFNAINT } from "../../store/models/CreateAtdp";
import { Slide, SlideProps } from "@material-ui/core";
import { InView } from "react-intersection-observer";

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="up" timeout={10000} ref={ref} {...props} />
));

const decodeHtml = (Base64: any) => {
  if (Base64 !== undefined) {
    let nativeHtml = atob(Base64);
    return nativeHtml;
  }
};

const bodyHtml = (html: any) => {
  let removeImage = html?.replace(/<img[^>]*>/g, "");
  let tmp = document.createElement("html");
  tmp.innerHTML = removeImage;
  let body = tmp.getElementsByClassName("body")[0];
  let footer = tmp.getElementsByClassName("footer")[0];
  let response = {
    body: "",
    footer: "",
  };
  if (body) {
    response.body = body.outerHTML;
    response.footer = footer.outerHTML;
  }
  return response;
};

interface Props {
  isOpen: boolean;
  isClose: any;
  onClick: any;
  data: IDataATDPFNAINT | null;
}

const FingerATDPFNA = ({ isOpen, isClose, onClick, data }: Props) => {
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");

  const classes = useStyles();
  const [terms, setTerms] = React.useState(false);
  const [check, setCheck] = React.useState(false);
  const { currentATDP } = useSelector(AtdpSelector);
  const [inView, setInView] = React.useState(false);

  let nativeHtml = decodeHtml(currentATDP);
  var result = nativeHtml?.replace(/{{NO.CEDULA}}/g, `${data?.documentNumber}`);

  let htmlStructure: { body: string; footer: string } = bodyHtml(result);

  const handleCheck = () => {
    setCheck(!check);
  };
  const handleClick = () => {
    onClick();
  };

  // useEffect(() => {
  //   return () => {
  //     onClick();
  //   };
  // });

  const Conditions = ({ template }: { template: any }) => {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: template.body }}></div>
        <InView onChange={setInView}>
          <div dangerouslySetInnerHTML={{ __html: template.footer }}></div>
        </InView>
      </div>
    );
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={maxWidth}
      className={classes.root}
      open={isOpen}
      TransitionComponent={Transition}
    >
      {terms === false ? (
        <DialogContent className={classes.root}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>
              Autorización de tratamiento de datos personales
            </Typography>
          </div>

          <div className={classes.htmlContainer} id="conditions">
            <Conditions
              template={{
                body: htmlStructure.body,
                footer: htmlStructure.footer,
              }}
            ></Conditions>
          </div>
          <div>
            <div className={classes.textGroup}>
              <Checkbox
                className={classes.checkBox}
                checked={check}
                onChange={handleCheck}
                disabled={!inView}
              />
              <Typography className={classes.text}>
                Acepto política de Tratamiento de Datos Personales.
              </Typography>
              {/* <Link className={classes.link} onClick={() => setTerms(true)}>
                &nbsp;Términos
              </Link>
              <Typography className={classes.text}>&nbsp;y </Typography>
              <Link className={classes.link} onClick={() => setTerms(true)}>
                &nbsp;Condiciones
              </Link> */}
            </div>

            <div className={classes.buttonsContainer}>
              <div className={classes.cancelContainer}>
                <SecondaryButton
                  title={"Cancelar"}
                  props={{ onClick: isClose }}
                />
              </div>
              <div className={classes.aceptContainer}>
                <PrimaryButton
                  title={"Aceptar"}
                  props={{ disabled: !check, onClick: handleClick }}
                />
              </div>
            </div>
          </div>
        </DialogContent>
      ) : (
        <DialogContent className={classes.root} style={{ height: "40%" }}>
          <div className={classes.headerContainer}>
            <div className={classes.iconContainer}>
              <ArrowBackIosIcon onClick={() => setTerms(false)} />
            </div>
            <div className={classes.titleContainerIcon}>
              <Typography className={classes.title}>Términos</Typography>
            </div>
          </div>
          <div className={classes.htmlContainer}>
            <Conditions
              template={{
                body: htmlStructure.body,
                footer: htmlStructure.footer,
              }}
            ></Conditions>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles((_theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
  },

  title: {
    font: "normal normal bold 18px/23px Muli",
    color: "#FA5A00",
  },
  titleContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    margin: "20px auto",
    textAlign: "center",
  },
  buttonsContainer: {
    display: "flex",
    width: "100%",
    marginBottom: "40px",
    marginTop: "22px",
    justifyContent: "center",
  },
  aceptContainer: {
    width: "147px",
  },
  cancelContainer: {
    width: "147px",
    marginRight: "30px",
  },
  checkBox: {
    "& .MuiIconButton-label": {
      color: "#1D71B8 !important",
    },
    "& .MuiSvgIcon-root": {
      fontSize: 28,
    },
  },
  text: {
    font: "normal normal normal 14px/18px Muli",
  },
  link: {
    font: "normal normal normal 14px/18px Muli",
    color: "#009BDD",
    cursor: "pointer",
  },
  textGroup: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  htmlContainer: {
    height: "30%",
    overflow: "auto",
  },
  iconContainer: {
    width: "50px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    color: "#FA5A00",
    cursor: "pointer",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
  titleContainerIcon: {
    display: "flex",
    width: "50%",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "20px",
    marginLeft: "13%",
    marginRight: "auto",
    textAlign: "center",
  },
}));

export default FingerATDPFNA;
