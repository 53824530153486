import {
  CustomTypography,
  FullLoader,
} from "@dg-bucaramanga/react-components-dg-qa";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import ErrorIcon from "../../assets/Imagen_Error.svg";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import { CompanyIcon } from "../../components/Icons/CompanyIcon";
import { ControlledSwitch } from "../../components/Inputs/ControlledSwitch";
import { InputRounded } from "../../components/Inputs/InputRounded";
import ViewLayout from "../../components/Layouts/ViewLayout";
import { AlertIconModal } from "../../components/modal/AlertIconModal";
import { REQUIRED_MESSAGE } from "../../config/const";
import {
  createTypeProcessAC,
  getTypeProcessViewAC,
  typeProcessAuditAC,
  updateTypeProcessAC,
} from "../../store/action-creators/TypeProcessActionCreators";
import { CommonSelector, setError } from "../../store/slices/CommonSlice";
import { CompanySelector } from "../../store/slices/CompanySlice";
import {
  setCurrentTypeProcess,
  setShowForm,
  setSuccess,
  TypeProcesses,
  TypeProcessSelector,
} from "../../store/slices/TypeProcessSlice";
import { IAudit, ITypeProcess } from "../../store/types";
import { typeProcessMask } from "../../utils/general/masks";

const initialValues: ITypeProcess = {
  code: "",
  company: "",
  description: "",
  name: "",
  state: true,
};
const schema = yup.object().shape({
  company: yup.string().required(REQUIRED_MESSAGE),
  code: yup
    .string()
    .max(50, "El campo Código excede el tamaño permitido de 50 caracteres."),
  name: yup
    .string()
    .max(
      50,
      "El campo Nombre de trámite excede el tamaño permitido de 50 caracteres."
    )
    .required(REQUIRED_MESSAGE),
  description: yup
    .string()
    .max(
      250,
      "El campo Descripción excede el tamaño permitido de 250 caracteres."
    )
    .required(REQUIRED_MESSAGE),
  state: yup.bool(),
});

/**
 * @description Vista que contiene el formulario para crear o modificar la informacion de los type process
 * @returns { React.FC<{}> }
 */
export const TypeProcess: React.FC<{}> = () => {
  const {
    isLoading,
    error,
    success,
    currentTypeProcess,
    typeProcessAudit,
    paginatorTypeProcess,
  } = useSelector(TypeProcessSelector);
  const { currentCompany } = useSelector(CompanySelector);
  const [dataAudit, setDataAudit] = useState<IAudit>();
  /** Control de el formulario mediante useForm */
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ITypeProcess>({
    shouldUnregister: false,
    defaultValues: currentTypeProcess
      ? { ...currentTypeProcess, company: currentCompany?.businessname }
      : { ...initialValues, company: currentCompany?.businessname },
    resolver: yupResolver(schema),
  });
  const [message, setMessage] = React.useState("");
  const [isEdit, setIsEdit] = React.useState(!Boolean(currentTypeProcess));
  const { rol } = useSelector(CommonSelector);
  const dispatch = useDispatch();

  //#region
  React.useEffect(() => {
    if (error) {
      setMessage(error);
    } else if (success) {
      setMessage(success);
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, success]);

  /** Ejecutar auditoria cuando se realice un cambio en el hook  typeProcessAudit*/
  useEffect(() => {
    if (typeProcessAudit) {
      setDataAudit(typeProcessAudit);
    }
  }, [typeProcessAudit]);

  //#endregion

  const handleAudit = () => {
    if (currentTypeProcess) {
      dispatch(typeProcessAuditAC(currentTypeProcess));
    }
  };

  /**
   * Metodo que se ejecuta cuando hay algun cambio en cualquiera de los inputs del formulario se inserta el valor en el hook del formulario
   * @param { any } e Parametro que representa el target del input que se está insertando informacion
   */
  const handleChange = (e: any) => {
    switch (e.target.name) {
      case "code":
        setValue("code", typeProcessMask(e.target.value, "code"));
        break;
      case "name":
        setValue("name", typeProcessMask(e.target.value, "name"));
        break;
      case "description":
        setValue("description", typeProcessMask(e.target.value, "description"));
        break;

      default:
        break;
    }
  };

  /**
   * Metodo que recolecta la informacion almacenada en los inputs de los formularios para eviarlo a los diferentes metodos de actualizacion o almacenado
   * @param { any } data
   */
  const formSubmit = async (data: any) => {
    const typeProcess: ITypeProcess = {
      ...data,
      company: "c6937b97-8a44-454e-84c4-71a9a6d13455",
    };
    if (
      typeProcess.name?.trim().length === 0 ||
      typeProcess.description?.trim().length === 0
    )
      setMessage(
        "Validar los campos Código, Nombre del tramite y Descripción, no pueden estar vacios."
      );
    else {
      if (currentTypeProcess) {
        dispatch(updateTypeProcessAC(typeProcess));
      } else {
        dispatch(createTypeProcessAC(typeProcess));
      }
    }
  };

  return (
    <ViewLayout
      handleIconClick={() => {
        dispatch(setShowForm(false));
        dispatch(setCurrentTypeProcess(undefined));
      }}
      setIsEdit={setIsEdit}
      isEdit={isEdit}
      isNew={currentTypeProcess ? false : true}
      headerTitle={`${!currentTypeProcess ? "Crear t" : "T"}ipo de trámite`}
      editOption={true}
      handleAudit={() => handleAudit()}
      dataAudit={dataAudit}
      fileName="Auditoría Tipo de Trámite"
    >
      <form onChange={handleChange} onSubmit={handleSubmit(formSubmit)}>
        <Grid container>
          <Grid container item lg={6} md={9} sm={12} xs={12} spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                label="Empresa"
                name="company"
                defaultValue="Empresa Prueba"
                control={control}
                props={{
                  disabled: true /*!isEdit,*/,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                label="Código"
                name="code"
                control={control}
                props={{
                  helperText: errors.code?.message,
                  error: errors.name ? true : false,
                  disabled: !!currentTypeProcess
                    ? currentTypeProcess.haveProcess
                      ? true
                      : !isEdit
                    : !isEdit,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                label="Nombre trámite"
                name="name"
                control={control}
                props={{
                  helperText: errors.name?.message,
                  error: errors.name ? true : false,
                  disabled: !!currentTypeProcess
                    ? currentTypeProcess.haveProcess
                      ? true
                      : !isEdit
                    : !isEdit,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputRounded
                name="description"
                control={control}
                label="Descripción"
                props={{
                  helperText: errors.description?.message,
                  error: errors.description ? true : false,
                  multiline: true,
                  rows: 10,
                  name: "description",
                  disabled: !isEdit,
                }}
              />
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={5} md={3} sm={12} xs={12}>
                <CustomTypography type="definition">Activo</CustomTypography>
                <ControlledSwitch
                  control={control}
                  name="state"
                  isDisabled={
                    rol !== "BIOMETRIA RNEC" &&
                    currentTypeProcess?.idState === 2
                      ? true
                      : !isEdit
                  }
                  checked={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid></Grid>
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            justifyContent="center"
          >
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <PrimaryButton
                title={currentTypeProcess ? "Actualizar" : "Guardar"}
                props={{ type: "submit", disabled: !isEdit /* || !isValid */ }}
              />
            </Grid>
          </Grid>
        </Grid>
        <AlertIconModal
          open={message ? true : false}
          handleClose={() => {
            if (success) {
              // Limpiar contenido en las alertas, en el formulario y retornar a la vista principal
              setMessage("");
              dispatch(setSuccess(""));
              dispatch(setShowForm(false));
              dispatch(TypeProcesses([]));
              dispatch(setCurrentTypeProcess(undefined));
              dispatch(getTypeProcessViewAC(paginatorTypeProcess));
            } else {
              setMessage("");
              dispatch(setError(""));
            }
          }}
          icon={
            success ? (
              <CompanyIcon width={"92px"} height={"80px"} />
            ) : (
              <div>
                <img
                  src={ErrorIcon}
                  style={{ width: "92px", height: "80px" }}
                  alt="ErrorIcon"
                />
              </div>
            )
          }
          message={message}
        />
        <FullLoader open={isLoading} viewLoader={true} />
      </form>
    </ViewLayout>
  );
};
