import { Theme } from "@material-ui/core";
import {
  Grid,
  Typography,
  Container,
  makeStyles,
  Box,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import ArrowForward from "@material-ui/icons/ArrowForward";
import "../../../components/Fingerprint/FingerPrintStyle.css";
import ManualFingerprintDialog from "../../../components/Fingerprint/ManualFingerprintDialog";
import { PrimaryButton } from "../../../components/button/PrimaryButton";
import { SecondaryButton } from "../../../components/button/SecondaryButton";
import CaptureDialog from "../../../components/Fingerprint/CaptureDialog";
import ModalError from "../../../components/Fingerprint/ModalError";
import ModalSuccess from "../../../components/Fingerprint/ModalSuccess";
import CheckParticipant from "../../../components/Fingerprint/CheckParticipant";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import FingerATDP from "../../../components/Atdp/FingerATDP";
import Hands from "../../../assets/hands.png";
import FullLoader from "../../../components/Loader/FullLoader.component";
import { AlertIconModal } from "../../../components/modal/AlertIconModal";
import { NoDeviceIcon } from "../../../components/Icons/NoDeviceIcon";
import ValidateParticipants from "../../../components/Fingerprint/ValidateParticipants";
import useFingerPrintCapture from "../../../hooks/useFingerPrintCapture";

const useStyles = makeStyles((_theme: Theme) => ({
  participantsContainer: {
    width: "30%",
    background: "#FAFAFA 0% 0% no-repeat padding-box",
    border: "1px solid #DDDDDD",
    borderRadius: "10px",
    padding: "17px 25px",
  },
  participantText: {
    font: "normal normal normal 14px/18px Muli",
    color: "#B2B2B2",
  },
  selectParticipantText: {
    font: "font: normal normal bold 14px/18px Muli",
    color: "#009BDD",
    fontWeight: "bold",
  },
  arrowContainer: {
    width: "10%",
    fontSize: "10px",
    color: "#009BDD",
    position: "absolute",
    left: "-20px",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

    [_theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      gap: "1rem",
    },
    "& div:nth-child(1)": {
      [_theme.breakpoints.up("md")]: {
        width: "144px",
        height: "40px",
      },
    },
    "& div:nth-child(2)": {
      [_theme.breakpoints.up("md")]: {
        width: "250px",
        height: "40px",
      },
    },
  },
  customCheckbox: {
    background: "none",
    "& .MuiCheckbox-root": {
      color: "#ffffff !important",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "18px !important",
    },
  },
  checkContainer: {
    width: "10%",
    color: "#009BDD",
    marginLeft: "10px",
  },
}));

interface FingerprintCaptureProps {
  companyId: string;
  changeTab: Function;
  roleLogged: boolean;
}

const FingerprintCapture = ({
  companyId,
  changeTab,
  roleLogged,
}: FingerprintCaptureProps) => {
  // custom hook
  const {
    businessAlert,
    errorMessage,
    dataParticipantState,
    isOpenModalValidateResult,
    dataATDPState,
    fingerATDP,
    validateParticipantsState,
    setMultipleParticipantInfo,
    isOpenModalErrorValidation,
    responseCapture,
    loadingFingerPrint,
    defaultCheck,
    fingerAlert,
    captureDialog,
    isramdomAvailable,
    fingerPrimary,
    fingerId,
    handPrimary,
    indexState,
    checkParticipantList,
    keyState,
    fingers,
    fingerprintJustification,
    manualJustification,
    handleChangeTab,
    handleMultipleDialogs,
    handleCaptureDialog,
    getsessionStorage,
    isButtonDisabled,
    setFingerAlert,
    clearTimer,
    resetCapture,
    setCaptureDialog,
    handleNextParticipant,
    setIsOpenModalValidateResult,
    setFingerATDP,
    setValidateParticipantsState,
    setIsOpenModalErrorValidation,
    dispatch,
    changeToManualFingerCapture,
    handleCheckbox,
    generateRandom,
    handleJustification,
  } = useFingerPrintCapture({ changeTab, companyId });
  // timeOut
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction={"column"}
        lg={12}
      >
        <Container>
          <Box className={classes.participantsContainer}>
            {getsessionStorage().map((item) => (
              <div
                key={item.id}
                style={{ display: "flex", width: "100%", position: "relative" }}
              >
                {indexState === item.id ? (
                  <div className={classes.arrowContainer}>
                    <ArrowForward style={{ fontSize: "18px" }} />
                  </div>
                ) : (
                  <></>
                )}
                <Typography
                  className={
                    indexState === item.id
                      ? classes.selectParticipantText
                      : classes.participantText
                  }
                >
                  {!!item.nameuser && item.nameuser}&nbsp;
                  {!!item.surname && item.surname}&nbsp;
                  {!item.nameuser && item.documentNumber}
                </Typography>
                <div className={classes.checkContainer}>
                  <CheckParticipant
                    isOpen={
                      item.id === 1
                        ? checkParticipantList.oneForm
                        : item.id === 2
                        ? checkParticipantList.twoForm
                        : item.id === 3
                        ? checkParticipantList.threeForm
                        : false
                    }
                  />
                </div>
              </div>
            ))}
          </Box>
          <Container style={{ margin: 0, padding: 0 }}>
            <div className={"handsContainer"} style={{ position: "relative" }}>
              <Box className={"fingerContainer"}>
                <img src={Hands} alt="hands" className={"imageHands"} />
                <div className={classes.customCheckbox}>
                  <FormControlLabel
                    className={"checkboxHands pinky-right"}
                    value="meñiqued"
                    control={
                      <Checkbox
                        color="default"
                        className={"checkboxCustom"}
                        disabled={defaultCheck.meñiqued.disabled}
                        checked={defaultCheck.meñiqued.checked}
                      />
                    }
                    label="meñique"
                    labelPlacement="bottom"
                    onChange={(e) => handleCheckbox(e, "derecha", "Meñique")}
                  />
                  <FormControlLabel
                    className={"checkboxHands ringFinger-right"}
                    value="anulard"
                    control={
                      <Checkbox
                        color="default"
                        className={"checkboxCustom"}
                        disabled={defaultCheck.anulard.disabled}
                        checked={defaultCheck.anulard.checked}
                      />
                    }
                    label="Anular"
                    labelPlacement="bottom"
                    onChange={(e) => handleCheckbox(e, "derecha", "Anular")}
                  />
                  <FormControlLabel
                    className={"checkboxHands middleFinger-right"}
                    value="corazond"
                    control={
                      <Checkbox
                        color="default"
                        className={"checkboxCustom"}
                        disabled={defaultCheck.corazond.disabled}
                        checked={defaultCheck.corazond.checked}
                      />
                    }
                    label="Corazón"
                    labelPlacement="bottom"
                    onChange={(e) => handleCheckbox(e, "derecha", "Corazón")}
                  />
                  <FormControlLabel
                    className={"checkboxHands indexFinger-right"}
                    value="indiced"
                    control={
                      <Checkbox
                        color="default"
                        className={"checkboxCustom"}
                        disabled={defaultCheck.indiced.disabled}
                        checked={defaultCheck.indiced.checked}
                      />
                    }
                    label="Índice"
                    labelPlacement="bottom"
                    onChange={(e) => handleCheckbox(e, "derecha", "Índice")}
                  />
                  <FormControlLabel
                    className={"checkboxHands thumb-right"}
                    value="pulgard"
                    control={
                      <Checkbox
                        color="default"
                        className={"checkboxCustom"}
                        disabled={defaultCheck.pulgard.disabled}
                        checked={defaultCheck.pulgard.checked}
                      />
                    }
                    label="Pulgar"
                    labelPlacement="bottom"
                    onChange={(e) => handleCheckbox(e, "derecha", "Pulgar")}
                  />
                  <FormControlLabel
                    className={"checkboxHands pinky-left"}
                    value="meñiquei"
                    control={
                      <Checkbox
                        color="default"
                        className={"checkboxCustom"}
                        disabled={defaultCheck.meñiquei.disabled}
                        checked={defaultCheck.meñiquei.checked}
                      />
                    }
                    label="meñique"
                    labelPlacement="bottom"
                    onChange={(e) => handleCheckbox(e, "izquierda", "Meñique")}
                  />
                  <FormControlLabel
                    className={"checkboxHands ringFinger-left"}
                    value="anulari"
                    control={
                      <Checkbox
                        color="default"
                        className={"checkboxCustom"}
                        disabled={defaultCheck.anulari.disabled}
                        checked={defaultCheck.anulari.checked}
                      />
                    }
                    label="Anular"
                    labelPlacement="bottom"
                    onChange={(e) => handleCheckbox(e, "izquierda", "Anular")}
                  />
                  <FormControlLabel
                    className={"checkboxHands middleFinger-left"}
                    value="corazoni"
                    control={
                      <Checkbox
                        color="default"
                        className={"checkboxCustom"}
                        disabled={defaultCheck.corazoni.disabled}
                        checked={defaultCheck.corazoni.checked}
                      />
                    }
                    label="Corazón"
                    labelPlacement="bottom"
                    onChange={(e) => handleCheckbox(e, "izquierda", "Corazón")}
                  />
                  <FormControlLabel
                    className={"checkboxHands indexFinger-left"}
                    value="indicei"
                    control={
                      <Checkbox
                        color="default"
                        className={"checkboxCustom"}
                        disabled={defaultCheck.indicei.disabled}
                        checked={defaultCheck.indicei.checked}
                      />
                    }
                    label="Índice"
                    labelPlacement="bottom"
                    onChange={(e) => handleCheckbox(e, "izquierda", "Índice")}
                  />
                  <FormControlLabel
                    className={"checkboxHands thumb-left"}
                    value="pulgari"
                    control={
                      <Checkbox
                        color="default"
                        className={"checkboxCustom"}
                        disabled={defaultCheck.pulgari.disabled}
                        checked={defaultCheck.pulgari.checked}
                      />
                    }
                    label="Pulgar"
                    labelPlacement="bottom"
                    onChange={(e) => handleCheckbox(e, "izquierda", "Pulgar")}
                  />
                </div>
                <Grid
                  item
                  sm={12}
                  style={{ position: "absolute", zIndex: 100, bottom: "1em" }}
                >
                  {isramdomAvailable ? (
                    <PrimaryButton
                      title={"Aleatorio"}
                      props={{
                        onClick: generateRandom,
                        // disabled: isreadyFormat,
                      }}
                    ></PrimaryButton>
                  ) : (
                    <PrimaryButton
                      title={"Cambiar a Manual"}
                      props={{
                        onClick: changeToManualFingerCapture,
                      }}
                    ></PrimaryButton>
                  )}
                </Grid>
              </Box>
            </div>
          </Container>
          <div className={classes.buttonsContainer}>
            <div>
              <SecondaryButton
                title={"Anterior"}
                props={{
                  onClick: handleChangeTab,
                  startIcon: <ArrowBackIos style={{ color: "#009BDD" }} />,
                }}
              ></SecondaryButton>
            </div>
            <div>
              <PrimaryButton
                title={"Capturar huellas"}
                props={{
                  onClick: handleCaptureDialog,
                  disabled: isButtonDisabled(),
                  endIcon: <ArrowForwardIos />,
                }}
              ></PrimaryButton>
            </div>
          </div>
        </Container>
      </Grid>
      {/* Modal change to manual capture */}
      <ManualFingerprintDialog
        isOpen={fingerAlert}
        isClose={() => setFingerAlert(false)}
        fingerprintJustification={fingerprintJustification}
        handleSubmit={handleJustification}
        valueSaved={
          !!manualJustification ? manualJustification.toString() : null
        }
      />
      {/* Modal captura de huellas */}
      <CaptureDialog
        keyProp={keyState}
        isOpen={captureDialog}
        finger={fingerPrimary ? fingerPrimary : null}
        hand={handPrimary ? handPrimary : null}
        arrayFinger={fingers}
        clearTimer={clearTimer}
        handleAcceptButton={handleMultipleDialogs}
        isClose={() => {
          resetCapture();
          clearTimer();
          return setCaptureDialog(false);
        }}
        value={fingerId}
      />
      {/* Modal Result fingerprint validation - satistactoria */}
      <ModalSuccess
        dataParticipant={dataParticipantState}
        arrayFinger={fingers}
        isOpen={isOpenModalValidateResult}
        nextParticipant={handleNextParticipant}
        sizeParticipant={getsessionStorage().length}
        index={indexState}
        isClose={() => {
          resetCapture();
          return setIsOpenModalValidateResult(false);
        }}
        informationMessage={responseCapture && responseCapture.information}
      />
      {/* politica tratamiento datos personales */}
      <FingerATDP
        data={dataATDPState}
        onClick={() => setFingerATDP(false)}
        isOpen={fingerATDP}
        isClose={() => {
          resetCapture();
          return setFingerATDP(false);
        }}
      />
      {/* modal show resume for multiple participants after validation */}
      <ValidateParticipants
        isOpen={validateParticipantsState}
        finalize={() => {
          dispatch(setMultipleParticipantInfo([]));
          return handleChangeTab();
        }}
        isClose={() => {
          resetCapture();
          return setValidateParticipantsState(false);
        }}
      />
      {/* modal de resultado si la validacion de huellas no es satisfactoria  */}
      <ModalError
        isOpen={isOpenModalErrorValidation}
        onClose={() => {
          setIsOpenModalErrorValidation(false);
        }}
        informationMessage={responseCapture && responseCapture.information}
        returnToCapture={() => {
          resetCapture();
          return setIsOpenModalErrorValidation(false);
        }}
        returnToForm={() => {
          handleChangeTab();
          return setIsOpenModalErrorValidation(false);
        }}
        mustReturnToForm={
          responseCapture && responseCapture.informationCode?.trim() === "RNEC0"
            ? true
            : false
        }
        isUserFNA={roleLogged}
        sizeParticipant={getsessionStorage().length}
        nextParticipant={handleNextParticipant}
        index={indexState}
        dataParticipant={dataParticipantState}
        arrayFinger={fingers}
      />
      {/* modal de resultado si la validacion de la sede del usuario no existe  */}
      <AlertIconModal
        open={businessAlert}
        handleClose={() => {
          handleChangeTab();
        }}
        icon={<NoDeviceIcon />}
        message={errorMessage}
      />

      <FullLoader open={loadingFingerPrint} />
    </>
  );
};
export default FingerprintCapture;
