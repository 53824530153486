import React, { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import useFingerPrintCaptureForm from "../../../hooks/useFingerPrintForm";
import FingerATDP from "../../../components/Atdp/FingerATDP";
import DeviceAlert from "../../../components/Fingerprint/DeviceAlert";
import FullLoader from "../../../components/Loader/FullLoader.component";
import { FingerprintForm } from "./FingerprintForm";
import { FingerprintForm_FNA_INT } from "./FingerprintForm_FNA_INT";

interface FingerprintFormProps {
  token: string;
  handleChangeTab: Function;
  roleLogged: "FNA" | "INT" | "NRM";
  haveGeolocatization: boolean;
}

const FingerprintFormMain = ({
  handleChangeTab,
  roleLogged,
  haveGeolocatization,
}: FingerprintFormProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    submitForm,
    handleProcessType,
    processTypeState,
    handleNumberParticipants,
    numberParticipantsLocal,
    submitState,
    primaryParticipant,
    setTemplate,
    setDeviceAlert,
    handleValidate,
    handleDataSaveForm,
    template,
    loadingFingerPrint,
    deviceAlert,
    handleStatesToFalse,
  } = useFingerPrintCaptureForm({
    enqueueSnackbar,
    roleLogged,
  });

  return (
    <>
      {roleLogged === "FNA" || roleLogged === "INT" ? (
        <>
          <FingerprintForm_FNA_INT
            formSubmit={submitForm}
            handleValidate={handleValidate}
            haveGeolocatization={haveGeolocatization}
            handleChangeTab={handleChangeTab}
            handleStatesToFalse={handleStatesToFalse}
            roleLogged={roleLogged}
            setTemplate={setTemplate}
            template={template}
          />
        </>
      ) : (
        <>
          <FingerprintForm
            handleValidate={handleValidate}
            formSubmit={submitForm}
            handleDataSaveForm={handleDataSaveForm}
            handleProcessType={handleProcessType}
            handleNumberParticipants={handleNumberParticipants}
            processTypeState={processTypeState}
            numberParticipantsLocal={numberParticipantsLocal}
            submitState={submitState}
            primaryParticipant={primaryParticipant}
            setTemplate={setTemplate}
            template={template}
            handleChangeTab={handleChangeTab}
            handleStatesToFalse={handleStatesToFalse}
          />
        </>
      )}

      <DeviceAlert
        isOpen={deviceAlert}
        isClose={() => {
          setDeviceAlert(false);
        }}
      />
      <FullLoader open={loadingFingerPrint} />
    </>
  );
};
export { FingerprintFormMain };
