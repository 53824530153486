import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetState,
  setLoadingFingerPrint,
  FingerPrintSelector,
  setMultipleParticipantInfo,
  setResponseSocket,
} from "../store/slices/FingerPrintSlice";
import {
  IFingerUnit,
  ICaptureFinger,
  IDataFinger,
  IDataParticipant,
  ISpecificWebsocketResponse,
} from "../store/models/FingerPrintModel";
import { IDataATDP } from "../store/models/CreateAtdp";
import { useSnackbar } from "notistack";
import { TIME_CAPTURE_FINGERPRINT } from "../config/config";
import {
  setBusinessUnitFingerAC,
  setAndValidateDataParticipantAC,
  GetFingerprintJustificationAC,
} from "../store/action-creators/FingerprintActionCreators";
import "moment/locale/es";
import useFingerWebSocket from "./useFingerWebSocket";
import { getLocationUserStore } from "../utils/general/utils";

const initialValues = {
  meñiqued: { checked: false, disabled: true, firstCapture: true },
  anulard: { checked: false, disabled: true, firstCapture: true },
  corazond: { checked: false, disabled: true, firstCapture: true },
  indiced: { checked: false, disabled: true, firstCapture: true },
  pulgard: { checked: false, disabled: true, firstCapture: true },
  meñiquei: { checked: false, disabled: true, firstCapture: true },
  anulari: { checked: false, disabled: true, firstCapture: true },
  corazoni: { checked: false, disabled: true, firstCapture: true },
  indicei: { checked: false, disabled: true, firstCapture: true },
  pulgari: { checked: false, disabled: true, firstCapture: true },
};

type useFingerPrintCaptureProps = {
  changeTab: any;
  companyId: any;
};

const useFingerPrintCapture = ({
  changeTab,
  companyId,
}: useFingerPrintCaptureProps) => {
  const { enqueueSnackbar } = useSnackbar();
  // redux dispatch
  const dispatch = useDispatch();
  // redux selector
  const {
    responseSocket,
    fingerDevice,
    businessUnitFinger,
    selectProcess,
    responseCapture,
    loadingFingerPrint,
    fingerprintJustification,
  } = useSelector(FingerPrintSelector);

  // react state
  const [indexState, setIndexState] = useState(1);
  const [fingerAlert, setFingerAlert] = useState(false);
  const [captureDialog, setCaptureDialog] = useState(false);
  const [isramdomAvailable, setIsramdomAvailable] = useState(true);
  const [fingerPrimary, setFingerPrimary] = useState<string | null>(null);
  const [fingerId, setFingerId] = useState<string | null>(null);
  const [handPrimary, setHandPrimary] = useState<string | null>(null);
  const [fingers, setFingers] = useState<Array<IFingerUnit>>([]);
  const [keyState, setKeyState] = useState<number>(0);
  const [isOpenModalErrorValidation, setIsOpenModalErrorValidation] =
    useState(false);
  const [isOpenModalValidateResult, setIsOpenModalValidateResult] =
    useState(false);
  const [isOpenModalRetryCapture, setIsOpenModalRetryCapture] = useState(false);
  const [defaultCheck, setDefaultCheck] = useState(initialValues);
  const [fingerATDP, setFingerATDP] = useState(false);
  const [validateParticipantsState, setValidateParticipantsState] =
    useState(false);
  const [dataParticipantState, setDataParticipantState] =
    useState<IDataFinger | null>(null);
  const [manualJustification, setManualJustification] = useState<number | null>(
    null
  );
  const [checkParticipantList, setCheckParticipantList] = useState({
    oneForm: false,
    twoForm: false,
    threeForm: false,
  });
  const [dataATDPState, setDataATDP] = useState<IDataATDP>({
    noCedula: "",
    name: "",
    surname: "",
    place: "",
    idDemand: 0,
  });
  const [timeOutId, setTimeOutId] = useState<
    string | number | NodeJS.Timeout | undefined
  >(undefined);
  const [tabstate, setTabstate] = useState<number>(0);
  const [businessAlert, setBusinessAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  //constants
  const arrayForSend: ICaptureFinger[] = [];

  // react hooks
  const { disconnect } = useFingerWebSocket();

  // custom functions
  // clear timer for capture modal
  function clearTimer() {
    clearInterval(timeOutId);
  }
  // get sorted sessionStorage items (participant data)
  const getsessionStorage = () => {
    let info: IDataFinger[] = [];
    for (let key in sessionStorage) {
      if (key.length < 3) {
        let getInfo = sessionStorage.getItem(key.toString());
        if (getInfo) {
          info.push(JSON.parse(getInfo));
        }
      }
    }
    info.sort(function (a, b) {
      return a.id - b.id;
    });
    return info;
  };

  const returnCheckListParticipant = (index: any) => {
    if (index === 1)
      return setCheckParticipantList({
        ...checkParticipantList,
        oneForm: true,
      });
    if (index === 2)
      return setCheckParticipantList({
        ...checkParticipantList,
        twoForm: true,
      });
    if (index === 3)
      return setCheckParticipantList({
        ...checkParticipantList,
        threeForm: true,
      });
  };

  const handleChangeTab = () => {
    dispatch(resetState());
    changeTab(0);
  };

  function handleCaptureDialog() {
    setCaptureDialog(true);
    let timeoutId = setTimeout(() => {
      setFingerId(null);
      setFingers([]);
      setCaptureDialog(false);
      disconnect();
      enqueueSnackbar("Tiempo de espera agotado para la captura de huellas.", {
        variant: "error",
      });
      dispatch(setResponseSocket([]));
      dispatch(setLoadingFingerPrint(false));
    }, TIME_CAPTURE_FINGERPRINT * 1000);
    setTimeOutId(timeoutId);
  }
  // Handle accept button (manage time in view and capture)
  const handleMultipleDialogs = (value: any) => {
    setCaptureDialog(false);
    const element = fingers.find((item) => item.value === value);
    if (element) {
      setKeyState(keyState + 1);
      setFingerPrimary(element.finger);
      setHandPrimary(element.hand);
      setFingerId(element.value);
      setTimeout(() => {
        setCaptureDialog(true);
      }, 500);
    } else {
      setAllChecksToDefault();
      clearTimer();
      sendDataToValidate();
    }
  };

  /**
   * Handle manual chackbox finger selection
   * @param event Event from component react checkbox
   * @param handFinger string mano a seleccionar
   * @param fingerParam string dedo
   * @returns
   */
  const handleCheckbox = (event: any, handFinger: any, fingerParam: any) => {
    const checkName = event.target.value;
    let copyState = { ...defaultCheck };
    type ObjectKey = keyof typeof defaultCheck;

    let verify = fingers.some((item) => item.value === checkName);
    if (fingers.length > 0) {
      if (!!verify) {
        copyState[checkName as ObjectKey].firstCapture = false;
        copyState[checkName as ObjectKey].checked = false;
        const remove = fingers.filter((item) => item.value !== checkName);
        setDefaultCheck(copyState);
        isDisabledCheckbox();
        return setFingers(remove);
      } else {
        copyState[checkName as ObjectKey].firstCapture = false;
        copyState[checkName as ObjectKey].checked = true;
        setDefaultCheck(copyState);
        isDisabledCheckbox();
        return setFingers([
          ...fingers,
          { value: checkName, hand: handFinger, finger: fingerParam },
        ]);
      }
    } else if (fingers.length === 0) {
      copyState[checkName as ObjectKey].firstCapture = false;
      copyState[checkName as ObjectKey].checked = true;
      setFingerPrimary(fingerParam);
      setHandPrimary(handFinger);
      setFingerId(checkName);
      setKeyState(0);
      setDefaultCheck(copyState);
      isDisabledCheckbox();
      return setFingers([
        ...fingers,
        { value: checkName, hand: handFinger, finger: fingerParam },
      ]);
    }
  };

  function isDisabledCheckbox() {
    let conditional = 0;
    let copyState = { ...defaultCheck };
    Object.entries(defaultCheck).forEach(([, value]) => {
      if (value.checked) {
        conditional += 1;
      }
    });

    if (conditional === 2) {
      Object.entries(copyState).forEach(([, value]) => {
        if (!value.checked) {
          value.disabled = true;
        }
      });
      setDefaultCheck(copyState);
    } else {
      Object.entries(copyState).forEach(([, value]) => {
        if (!value.checked) {
          value.disabled = false;
        }
      });
      setDefaultCheck(copyState);
    }
  }

  function isButtonDisabled() {
    let conditional = 0;
    Object.entries(defaultCheck).forEach(([, value]) => {
      if (value.checked) {
        conditional += 1;
      }
    });

    if (fingers.length > 1 && conditional > 1) {
      return false;
    } else {
      return true;
    }
  }
  /**
   * Cambia al siguiente participante si esta disponible o inicia validacion de identidad
   */
  const handleNextParticipant = () => {
    resetCapture();
    setAllChecksToDefault();
    disableIndicesFingers();
    setManualJustification(null);
    setIsOpenModalValidateResult(false);
    setIsOpenModalErrorValidation(false);
    let existsAnotherParticipant = getsessionStorage().some(
      (item) => item.id === indexState + 1
    );
    if (existsAnotherParticipant) {
      returnCheckListParticipant(indexState);
      setFingers([]);
      setIsramdomAvailable(true);
      generateRandom();
      setIndexState(indexState + 1);
      return returnDataATDP(indexState + 1);
    } else {
      if (getsessionStorage().length > 1) {
        setValidateParticipantsState(true);
      } else {
        changeTab(0);
      }
    }
  };

  const returnDataParticipant = (indexSearch: any) => {
    // eslint-disable-next-line array-callback-return
    getsessionStorage().map((item) => {
      if (indexSearch === item.id) {
        let data: IDataFinger = {
          id: item.id,
          nameuser: item.nameuser,
          documentType: item.documentType,
          idDocumentType: item.idDocumentType,
          phone: item.phone,
          surname: item.surname,
          documentNumber: item.documentNumber,
          email: item.email,
          idDemand: item.idDemand || null,
          idManualCaptureJustification:
            item.idManualCaptureJustification || null,
        };
        return setDataParticipantState(data);
      }
    });
  };

  const returnDataATDP = (indexSearch: any) => {
    // eslint-disable-next-line array-callback-return
    getsessionStorage().map((item) => {
      if (indexSearch === item.id) {
        let dataATDP: IDataATDP = {
          name: item.nameuser,
          surname: item.surname,
          place: "",
          noCedula: item.documentNumber,
          idDemand: item.idDemand || null,
        };
        return setDataATDP(dataATDP);
      }
    });
    setFingerATDP(true);
  };

  const resetCapture = () => {
    dispatch(setResponseSocket([]));
    setFingers([]);
  };

  // random functionalities
  function generateRandom() {
    setAllDisable();
    getRandomItem();
    getRandomItem();
  }

  /**
   * Valida si la huella generada random es primera captura
   * @param random String objectkey Para validar si es la primera captura
   * @returns {boolean}
   */
  function isValidateCapture(random: string): boolean {
    type ObjectKey = keyof typeof defaultCheck;
    return defaultCheck[random as ObjectKey].firstCapture ? true : false;
  }

  /**
   * Cambiar a manual la eleccion de huellas a manual
   */
  function changeToManualFingerCapture() {
    setFingerAlert(true);
    setAllAvailable();
  }

  /**
   * entrega una huella random
   */
  const getRandomItem = (): any => {
    let conditional = 0;
    let copyState = { ...defaultCheck };

    const keys = Object.keys(defaultCheck);

    const rand = keys[Math.floor(Math.random() * keys.length)];

    type ObjectKey = keyof typeof defaultCheck;

    Object.entries(defaultCheck).forEach(([, value]) => {
      if (!value.firstCapture) {
        conditional += 1;
      }
    });

    if (!isValidateCapture(rand)) {
      return getRandomItem();
    }

    copyState[rand as ObjectKey].firstCapture = false;
    copyState[rand as ObjectKey].checked = true;

    if (rand.at(-1) === "i") {
      setFingers((prevState) => [
        ...prevState,
        {
          value: rand,
          hand: "izquierda",
          finger: rand.substring(0, rand.length - 1),
        },
      ]);
    } else {
      setFingers((prevState) => [
        ...prevState,
        {
          value: rand,
          hand: "derecha",
          finger: rand.substring(0, rand.length - 1),
        },
      ]);
    }

    setDefaultCheck(copyState);

    if (conditional >= 6) {
      setIsramdomAvailable(false);
      return 1;
    }
  };

  const setPrimaryFingers = () => {
    if (fingers.length > 0) {
      const rand = fingers[0].value;
      setFingerPrimary(rand.substring(0, rand.length - 1));
      setHandPrimary(rand.at(-1) === "i" ? "izquierda" : "derecha");
      setFingerId(rand);
      setKeyState(0);
    }
  };

  /**
   * Disable "indices" fingers
   */
  function disableIndicesFingers() {
    let copyState = { ...defaultCheck };
    Object.entries(copyState).forEach(([finger, value]) => {
      if (finger === "indiced" || finger === "indicei") {
        value.firstCapture = false;
      }
    });
  }

  /**
   * set all checkbox fingerprint to default
   */
  function setAllChecksToDefault() {
    let copyState = { ...defaultCheck };
    Object.entries(copyState).forEach(([finger, value]) => {
      value.firstCapture = true;
      value.checked = false;
      value.disabled = true;
    });
  }

  /**
   * disable all checkbox fingerprint
   */
  function setAllDisable() {
    let copyState = { ...defaultCheck };
    Object.entries(copyState).forEach(([, value]) => {
      value.checked = false;
      value.disabled = true;
    });
    setFingers((prevState) => {
      return [];
    });
    setDefaultCheck(copyState);
  }

  /**
   * enable all checkbox fingerprint
   */
  function setAllAvailable() {
    let copyState = { ...defaultCheck };
    Object.entries(copyState).forEach(([, value]) => {
      value.disabled = false;
      value.checked = false;
    });
    setDefaultCheck(copyState);
    setFingers((prevState) => {
      return [];
    });
  }

  /**
   * send data to validate process
   */
  const dataSocket = () => {
    responseSocket.map((item: any) => {
      let idForSend = 0;
      let templateForSend: ISpecificWebsocketResponse = item.response;
      switch (item.id) {
        case "meñiqued":
          idForSend = 5;
          break;
        case "anulard":
          idForSend = 4;
          break;
        case "corazond":
          idForSend = 3;
          break;
        case "indiced":
          idForSend = 2;
          break;
        case "pulgard":
          idForSend = 1;
          break;
        case "meñiquei":
          idForSend = 10;
          break;
        case "anulari":
          idForSend = 9;
          break;
        case "corazoni":
          idForSend = 8;
          break;
        case "indicei":
          idForSend = 7;
          break;
        case "pulgari":
          idForSend = 6;
          break;
        default:
          idForSend = 0;
          break;
      }
      let itemForSend: ICaptureFinger = {
        Finger: idForSend,
        Template: templateForSend.prinfingerTemplate,
      };

      arrayForSend.push(itemForSend);
    });
  };

  // send data to validation and save in redux store result
  const sendData = () => {
    const { latitude, longitude } = getLocationUserStore();

    const stateIdCheckFinger = checkIdFingerArray();

    if (stateIdCheckFinger) {
      if (dataParticipantState && businessUnitFinger && selectProcess) {
        let data: IDataParticipant = {
          IdProcess: selectProcess.idProcessType,
          IdBusinessUnit:
            businessUnitFinger == null || businessUnitFinger == undefined
              ? 0
              : businessUnitFinger?.id,
          DescriptionProcess: selectProcess.descriptionProcess,
          DocumentTypeParticipator: !!Number(dataParticipantState.documentType)
            ? dataParticipantState.documentType
            : dataParticipantState.idDocumentType,
          DocumentNumber: dataParticipantState.documentNumber,
          NameParticipator: !!dataParticipantState.nameuser
            ? dataParticipantState.nameuser
            : "",
          SurnameParticipator: !!dataParticipantState.surname
            ? dataParticipantState.surname
            : "",
          PhoneParticipator: !!dataParticipantState.phone
            ? dataParticipantState.phone
            : "",
          MailParticipator: !!dataParticipantState.email
            ? dataParticipantState.email
            : "",
          Device:
            fingerDevice?.result === "SUPREMA"
              ? 1
              : fingerDevice?.result === "IDEMIA"
              ? 2
              : fingerDevice?.result === "THALES"
              ? 3
              : 0,
          Serial: responseSocket[0].response.deviceSerial,
          Ip: responseSocket[0].response.ip,
          Mac: responseSocket[0].response.mac,
          AcceptTerms: true,
          DetailTermsConditions: "",
          Latitude: latitude,
          Longitude: longitude,
          CreatedBy: 1,
          IdState: 1,
          FingerprintData: arrayForSend,
          idDemand: dataParticipantState.idDemand || null,
          idManualCaptureJustification: manualJustification || null,
        };
        dispatch(setAndValidateDataParticipantAC(data, enqueueSnackbar));
      }
    } else {
      setIsOpenModalRetryCapture(true);
      resetCapture();
    }
  };

  /**
   * Funcion que cambia el estado del modal de reintentar captura.
   */
  const handleCloseRetryDialog = () => {
    setIsOpenModalRetryCapture(!isOpenModalRetryCapture);
  };

  /**
   * Verifica si el elemento del id de la huella en el array es mayor que 1 y menor que 10.
   * @returns {boolean}
   */
  const checkIdFingerArray = (): boolean => {
    for (const item of arrayForSend) {
      if (item.Finger >= 1 && item.Finger <= 10) {
        return true;
      }
    }
    return false;
  };

  // handle send if capture is ready to send
  const sendDataToValidate = () => {
    dataSocket();
    if (!!arrayForSend) {
      sendData();
    }
  };

  // handle manual justification
  const handleJustification = (manualJustificationPassed: number) => {
    setManualJustification(manualJustificationPassed);
    return !!manualJustificationPassed;
  };
  // change modal to show after fingerprint validation
  // if informationCode === "RNEC1" show validate finger modal result
  // else show modal dialog with information about error
  const manageModalToShow = () => {
    if (
      responseCapture &&
      responseCapture.informationCode?.trim() === "RNEC1"
    ) {
      setIsOpenModalValidateResult(true);
    } else if (
      responseCapture &&
      (responseCapture.informationCode?.trim() !== "RNEC1" ||
        responseCapture.informationCode?.trim() === "RNEC0")
    ) {
      setIsOpenModalErrorValidation(true);
    }
  };

  const handleChangeTabPrincipal = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setTabstate(newValue);
  };

  // react hooks

  useEffect(() => {
    dispatch(GetFingerprintJustificationAC());
    setFingers([]);
    setFingerATDP(false);
    getsessionStorage();
    disableIndicesFingers();
    generateRandom();
    return () => {
      Object.keys(sessionStorage).forEach(function (key) {
        if (key.length === 1 || key.length === 2) {
          sessionStorage.removeItem(key);
        }
      });
      setAllChecksToDefault();
      setManualJustification(null);
      dispatch(resetState());
      clearTimer();
      disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPrimaryFingers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fingers]);

  useEffect(() => {
    returnDataParticipant(indexState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexState]);

  useEffect(() => {
    dispatch(
      setBusinessUnitFingerAC(
        {
          CompanyId: companyId,
          idDemand: getsessionStorage()[0]?.idDemand || null,
        },
        setErrorMessage,
        setBusinessAlert
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    manageModalToShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseCapture]);

  return {
    errorMessage,
    businessAlert,
    fingerAlert,
    captureDialog,
    isramdomAvailable,
    tabstate,
    indexState,
    checkParticipantList,
    keyState,
    fingers,
    dataParticipantState,
    isOpenModalValidateResult,
    dataATDPState,
    fingerATDP,
    validateParticipantsState,
    setMultipleParticipantInfo,
    isOpenModalErrorValidation,
    responseCapture,
    loadingFingerPrint,
    defaultCheck,
    fingerPrimary,
    fingerId,
    handPrimary,
    fingerprintJustification,
    manualJustification,
    handleJustification,
    handleChangeTab,
    handleMultipleDialogs,
    handleCaptureDialog,
    handleChangeTabPrincipal,
    getsessionStorage,
    isButtonDisabled,
    setFingerAlert,
    clearTimer,
    resetCapture,
    setCaptureDialog,
    handleNextParticipant,
    setIsOpenModalValidateResult,
    setFingerATDP,
    setValidateParticipantsState,
    setIsOpenModalErrorValidation,
    dispatch,
    changeToManualFingerCapture,
    handleCheckbox,
    generateRandom,
    isOpenModalRetryCapture,
    handleCloseRetryDialog,
  };
};

export default useFingerPrintCapture;
